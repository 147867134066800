import React from "react";
import styled from "styled-components";

const FooterBox = styled.div`
  background-color: rgb(30, 36, 45);
  width: 100%;
`;

const TextFooter = styled.div`
  padding: 50px;
  color: #fff;
`;

export default function Footer() {
  return (
    <FooterBox>
      <TextFooter>Todos os direitos reservados. Copyright © 2024</TextFooter>
    </FooterBox>
  );
}
