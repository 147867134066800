import React from "react";
import styled from "styled-components";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import velocar from "../images/velocar.png";
import anchoraway from "../images/anchoraway.png";
import mymail from "../images/mymail.png";
import coffeeclub from "../images/coffeeclub.png";
import foconofuturo from "../images/foconofuturo.png";

const BoxProjetos = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f9f9f9;
  width: 100%;
  padding: 50px;
  @media (min-width: 320px) and (max-width: 480px) {
    padding: 50px 0;
  }
  @media (min-width: 481px) and (max-width: 768px) {
    padding: 10px;
  }
`;

const BoxMain = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  padding: 4rem 0;
`;
const SlideItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
`;

const DisplayProjetos = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 15px;
  height: 550px;
  & a {
    text-decoration: none;
    color: #555;
    border-bottom: 1px solid #555;
  }
  & img {
    width: 100%;
    border-radius: 15px;
    margin: 15px;
  }
  & div {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  & p {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 110; /* Número máximo de linhas */
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
  }
`;
const SliderContainer = styled.div`
  width: 80%;
  margin-top: 25px;
  @media (min-width: 320px) and (max-width: 480px) {
    width: 85%;
  }
  @media (min-width: 481px) and (max-width: 768px) {
    width: 90%;
  }
`;

export default function Projetos() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <BoxProjetos id="projetos">
      <BoxMain>
        <h2>Projetos</h2>
        <SliderContainer>
          <Slider {...settings}>
            <SlideItem>
              <DisplayProjetos>
                <h3>Coffee Club</h3>
                <img src={coffeeclub} alt="site coffee club" />
                <a
                  href="https://coffeeclub-plum.vercel.app"
                  target="_blank"
                  rel="noreferrer"
                >
                  Live Demo
                </a>
                <div>
                  <p>Descrição:</p>
                  <p>
                    Um projeto pessoal desenvolvido com o objetivo de aprender e
                    explorar as stacks modernas, como Next.Js, Next Auth,
                    Prisma, Zod, Shadcn/Ui, Zustand, TailwindCSS.
                    <br /> com foco em aprofundar o conhecimento nessas
                    tecnlogias, aplicando boas praticas de desenvolvimento
                    frontend e backend
                  </p>
                </div>
              </DisplayProjetos>
            </SlideItem>
            <SlideItem>
              <DisplayProjetos>
                <h3>Foco no Futuro</h3>
                <img src={foconofuturo} alt="site fono no futuro" />
                <a
                  href="https://minha-receita.vercel.app"
                  target="_blank"
                  rel="noreferrer"
                >
                  Live Demo
                </a>
                <div>
                  <p>Descrição:</p>
                  <p>
                    Um projeto desenvolvido com o intuito para ajudar você a
                    gerenciar suas finanças de forma simples e eficiente, com o
                    objetivo de alcançar seus sonhos e metas de longo prazo..
                    <br />
                    Utilizado as ferramentas: Next.Js, Next Auth, Prisma, Zod,
                    Shadcn/Ui, TailwindCSS.
                  </p>
                </div>
              </DisplayProjetos>
            </SlideItem>
            <SlideItem>
              <DisplayProjetos>
                <h3>Velocar</h3>
                <img src={velocar} alt="site velocar" />
                <a
                  href="https://velocar.vercel.app"
                  target="_blank"
                  rel="noreferrer"
                >
                  Live Demo
                </a>
                <div>
                  <p>Descrição:</p>
                  <p>
                    Um projeto escolar desenvolvido a partir de um desafio com
                    HTML e CSS, e aprimorado com React, Redux e Styled
                    Components.
                    <br /> com foco em aprofundar o conhecimento nessas
                    tecnlogias, aplicando boas praticas de desenvolvimento
                    frontend
                  </p>
                </div>
              </DisplayProjetos>
            </SlideItem>
            <SlideItem>
              <DisplayProjetos>
                <h3>Mymail</h3>
                <img src={mymail} alt="site mymail" />
                <a
                  href="https://mymail-black.vercel.app"
                  target="_blank"
                  rel="noreferrer"
                >
                  Live Demo
                </a>
                <div>
                  <p>Descrição:</p>
                  <p>
                    Este projeto é uma caixa de email, que representa o envio e
                    recebimento de email, ele possui funcionalidades de
                    pesquisa, spam. A aplicação foi desenvolvida com React no
                    front-end com as tecnologias: React, TailwindCSS, Shadcn
                    <br /> com foco em aprofundar o conhecimento nessas
                    tecnlogias, aplicando boas praticas de desenvolvimento
                  </p>
                </div>
              </DisplayProjetos>
            </SlideItem>
            <SlideItem>
              <DisplayProjetos>
                <h3>Anchor Away</h3>
                <img src={anchoraway} alt="anchor away" />
                <a
                  href="https://anchor-away.vercel.app"
                  target="_blank"
                  rel="noreferrer"
                >
                  Live Demo
                </a>
                <div>
                  <p>Descrição:</p>
                  <p>
                    Um projeto pessoal desenvolvido com o objetivo de aprender e
                    explorar as stacks modernas, como Next.Js, Next Auth,
                    Prisma, Zod, Shadcn/Ui, Zustand, TailwindCSS.
                    <br /> com foco em aprofundar o conhecimento nessas
                    tecnlogias, aplicando boas praticas de desenvolvimento
                    frontend e backend
                  </p>
                </div>
              </DisplayProjetos>
            </SlideItem>
          </Slider>
        </SliderContainer>
      </BoxMain>
    </BoxProjetos>
  );
}
