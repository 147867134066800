import React from "react";
import styled from "styled-components";
import perfil from "../images/perfil.jpeg";
import { FaGithub, FaLinkedin, FaInstagram } from "react-icons/fa";

const BoxHome = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  background-color: #f9f9f9;
  font-family: "Open Sans", sans-serif;
  @media (min-width: 320px) and (max-width: 589px) {
    width: 100%;
  }
`;

const BoxMain = styled.div`
  display: flex;
  @media (min-width: 320px) and (max-width: 589px) {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 3px;
    margin-top: 60px;
  }
  @media (min-width: 481px) and (max-width: 768px) {
    padding: 20px;
  }
`;

const HeroText = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 30rem;
  & h1 {
    color: #2d2e32;
    font-size: 3.2rem;
    line-height: 1.1;
    margin-bottom: 1rem;
    margin-top: 1rem;
  }
  & p {
    color: #555;
    font-family: Mulish, sans-serif;
    font-size: 1.1rem;
    font-weight: 500;
    line-height: 1.6;
  }
  @media (min-width: 320px) and (max-width: 589px) {
    align-items: center;
    justify-content: center;
    & p {
      font-size: 1rem;
      text-align: center;
    }
    & h1 {
      font-size: 2.2rem;
      text-align: center;
      margin-bottom: 1rem;
      margin-top: 1rem;
    }
  }
`;

const HeroImg = styled.div`
  & img {
    border-radius: 15px;
    width: 280px;
    transition: all 1s ease-in-out;
  }
`;

const SpanStyled = styled.span`
  display: flex;
  align-items: center;
  margin: 15px;
  & p {
  }
  & svg {
    font-size: 38px;
    margin-right: 5px;
    :hover {
      color: #147efb;
    }
  }
  & ul {
    display: flex;
    & a {
      text-decoration: none;
      color: #000;
    }
  }
  @media (min-width: 481px) and (max-width: 768px) {
    margin: 8px;
    & a {
      display: flex;
    }
  }
  @media (min-width: 320px) and (max-width: 589px) {
    flex-direction: column;
    & svg {
      font-size: 34px;
    }
  }
`;

export default function Home() {
  return (
    <BoxHome id="home">
      <BoxMain>
        <HeroText>
          <h1>Front-End React Developer</h1>
          <p>
            Olá, meu nome é Ricardo Penga. um apaixonado e entusiasta
            desenvolvedor React Front-end, morando em Campinas, Brasil. 📍
          </p>
          <SpanStyled>
            <ul>
              <a
                href="https://github.com/ricardocisco"
                target="_blank"
                rel="noreferrer"
              >
                <FaGithub />
              </a>
              <a
                href="https://www.linkedin.com/in/ricardo-penga-58688929b/"
                target="_blank"
                rel="noreferrer"
              >
                <FaLinkedin />
              </a>
              <a href="/">
                <FaInstagram />
              </a>
            </ul>
          </SpanStyled>
        </HeroText>
        <HeroImg>
          <img src={perfil} alt="perfil" />
        </HeroImg>
      </BoxMain>
    </BoxHome>
  );
}
