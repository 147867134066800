import React, { useState } from "react";
import { Link } from "react-scroll";
import styled from "styled-components";
import { FaBars } from "react-icons/fa";
import { AiOutlineClose } from "react-icons/ai";

const NavbarBox = styled.div`
  position: fixed;
  display: flex;
  top: 0;
  width: 100%;
  height: ${({ active }) => (active ? "100vh" : "auto")};
  padding: 25px 40px 25px 50px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.09);
  background-color: #fff;
  font-size: 1.1rem;
  font-weight: 600;
  z-index: ${({ active }) => (active ? "9999" : "1")};
`;

const NavbarMain = styled.div`
  display: flex;
  width: 1600px;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
`;

const NavbarMenu = styled.div`
  display: flex;
  @media (min-width: 320px) and (max-width: 480px) {
    display: ${({ active }) => (active ? "none" : "flex")};
  }
`;

const NavbarList = styled.ul`
  display: flex;
  @media (min-width: 320px) and (max-width: 480px) {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 9998;
  }
`;

const NavbarLink = styled.li`
  list-style: none;
  :hover {
    color: #3083ff;
  }
`;

const LinkStyled = styled(Link)`
  cursor: pointer;
  margin-right: 20px;
  @media (min-width: 320px) and (max-width: 480px) {
    margin-right: 0px;
  }
`;

const NavbarTitle = styled.h3`
  white-space: nowrap;
  overflow: hidden;
  letter-spacing: 2px;
  border-right: 2px solid;
  animation: typing 1s steps(14), blink 0.5s step-end infinite alternate;
  @keyframes typing {
    from {
      width: 0;
    }
    to {
      width: 100%;
    }
  }
  @keyframes blink {
    50% {
      border-color: transparent;
    }
  }
`;

export const Icon = styled.i`
  display: none;
  @media (min-width: 320px) and (max-width: 480px) {
    display: block;
    position: absolute;
    top: 28px;
    right: 20px;
    font-size: 16px;
    z-index: 9999;
  }
`;

export default function Navbar() {
  const [active, setActive] = useState(false);

  const toggleMenu = () => {
    setActive(!active);
  };

  return (
    <NavbarBox>
      <NavbarMain>
        <div>
          <NavbarTitle>ricardo.dev</NavbarTitle>
        </div>
        <Icon onClick={toggleMenu}>
          {!active ? <FaBars /> : <AiOutlineClose />}
        </Icon>
        <NavbarMenu active={!active}>
          <NavbarList>
            <NavbarLink>
              <LinkStyled to="home" smooth={true} duration={300}>
                Home
              </LinkStyled>
            </NavbarLink>
            <NavbarLink>
              <LinkStyled to="sobre" smooth={true} duration={300}>
                Sobre
              </LinkStyled>
            </NavbarLink>
            <NavbarLink>
              <LinkStyled to="projetos" smooth={true} duration={300}>
                Projetos
              </LinkStyled>
            </NavbarLink>
            <NavbarLink>
              <LinkStyled to="contatos" smooth={true} duration={300}>
                Contato
              </LinkStyled>
            </NavbarLink>
          </NavbarList>
        </NavbarMenu>
      </NavbarMain>
    </NavbarBox>
  );
}
