import React from "react";
import styled from "styled-components";
import sobre from "../images/pc.jpg";
import {
  BiLogoTailwindCss,
  BiLogoJavascript,
  BiLogoTypescript,
  BiLogoReact,
  BiLogoCss3,
  BiLogoHtml5,
} from "react-icons/bi";
import { TbBrandNextjs } from "react-icons/tb";
import { SiStyledcomponents } from "react-icons/si";
import { IoLogoSass } from "react-icons/io";

const BoxSobre = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  padding: 15rem 0;
  background-color: #fff;
  @media (min-width: 320px) and (max-width: 589px) {
    padding: 7rem 0;
  }
`;

const BoxMain = styled.div`
  display: flex;
  @media (min-width: 320px) and (max-width: 589px) {
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  @media (min-width: 481px) and (max-width: 768px) {
    padding: 25px;
  }
`;

const BoxImg = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const BoxText = styled.div`
  font-family: Mulish, sans-serif;
  max-width: 30rem;
  margin-left: 35px;
  @media (min-width: 320px) and (max-width: 589px) {
    margin-left: 0px;
    padding: 20px;
  }
  & h3 {
    color: #3083ff;
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 0.2rem;
    text-transform: uppercase;
  }
  & h4 {
    color: #2d2e32;
    font-family: Poppins, sans-serif;
    font-size: 1.8rem;
    font-weight: 700;
    @media (min-width: 320px) and (max-width: 589px) {
      font-size: 1.1rem;
    }
  }
  & p {
    color: #555;
    font-family: Mulish, sans-serif;
    font-size: 1.1rem;
    font-weight: 500;
    line-height: 1.6;
    @media (min-width: 320px) and (max-width: 589px) {
      font-size: 0.9rem;
    }
  }
`;

const Image = styled.img`
  width: 400px;
  border-radius: 15px;
  @media (min-width: 320px) and (max-width: 589px) {
    width: 90%;
  }
  @media (min-width: 481px) and (max-width: 768px) {
    width: 300px;
  }
`;

const SpanStyled = styled.span`
  display: flex;
  align-items: center;
  margin: 15px;
  & p {
  }
  & svg {
    font-size: 50px;
    margin-right: 5px;
    :hover {
      color: #147efb;
    }
  }
  & ul {
    display: flex;
    & li {
      list-style: none;
    }
  }
  @media (min-width: 481px) and (max-width: 768px) {
    margin: 8px;
    & li {
      display: flex;
    }
  }
  @media (min-width: 320px) and (max-width: 589px) {
    flex-direction: column;
    & svg {
      font-size: 34px;
    }
  }
`;

export default function Sobre() {
  return (
    <BoxSobre id="sobre">
      <BoxMain>
        <BoxImg>
          <Image src={sobre} alt="imagem sobre"></Image>
        </BoxImg>
        <BoxText>
          <h3>Sobre Mim</h3>
          <h4>Desenvolvedor Front-end</h4>
          <p>
            Olá, meu nome é Ricardo e sou Desenvolvedor Frontend. sou um
            desenvolvedor especializado em front-end web e mobile
            <br />
            <br />
            Minha Stack principal atualmente é React/Next.js em combinação com
            Tailwind CSS/Styled-Components e TypeScript. tenho experiência
            também com React Native e Kotlin
          </p>
        </BoxText>
      </BoxMain>
      <SpanStyled>
        <p>Skills </p>
        <ul>
          <li>
            <BiLogoCss3 />
            <BiLogoHtml5 />
          </li>
          <li>
            <BiLogoJavascript />
            <BiLogoTypescript />
          </li>
          <li>
            <BiLogoReact />
            <TbBrandNextjs />
          </li>
          <li>
            <SiStyledcomponents />
            <IoLogoSass />
            <BiLogoTailwindCss />
          </li>
        </ul>
      </SpanStyled>
    </BoxSobre>
  );
}
